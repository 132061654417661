<template>
 <div>
  <modal className="w-11/12 sm:w-443px text-center pt-24" ref="successModal">
   <img
    :src="assets.icons.lg.checkmark"
    class="mx-auto mb-8"
    height="90"
    alt="Check Mark"
   />

   <div class="text-lg font-bold mb-4">
    {{ message }}
   </div>
   <p class="text-sm mb-4">{{ additionalText }}</p>
   <div class="flex justify-center" v-if="show">
    <button
     type="button"
     class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2"
     @click.prevent="closeSuccessModal"
    >
     <slot />
    </button>
   </div>
  </modal>
 </div>
</template>

<script>
 export default {
  props: {
   message: {
    type: String,
    default: ""
   },
   additionalText: {
    type: String,
    default: ""
   },
   show: {
    type: Boolean,
    default: true
   },
   link: {
    type: String,
    default: ""
   }
  },
  methods: {
   openSuccessModal() {
    this.$refs.successModal.open();
   },
   closeSuccessModal() {
    if (this.link) {
     this.$router.push({ name: this.link });
    }
    this.$refs.successModal.close();
   }
  }
 };
</script>
